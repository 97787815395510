import React, { useEffect, useState } from 'react';
import { Dialog, useMediaQuery } from '@material-ui/core';
import { ButtonPrimary, ButtonOutlined } from '@components/Reusables';
import useStyles from './HiredPlanCard.style';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { parseNumber } from '@utils';
import { updateUserSubscription, useCurrentUser } from '@apollo';
import { DELETE_ICON } from 'images/GoogleCloud/Icons';
import { useApolloClient } from '@apollo/client';
interface CongratsModalProps {
  data?: any;
  isOpen: boolean;
  RefreshItems: () => void;
}

export const HiredPlanCard: React.FC<CongratsModalProps> = ({
  data,
  isOpen,
  RefreshItems,
}) => {
  const [canceledCard, setCanceledCard] = useState(false);
  const [openPlanCard, setOpenPlanCard] = useState(false);
  const [openSeeMore, setOpenSeeMore] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { user: currentUser } = useCurrentUser();
  const classes = useStyles({ open: openPlanCard });
  const client = useApolloClient();
  const isDiscount = currentUser?.company ? true : false;
  const plan = data.planId;
  const GetBenefits = () => {
    return (
      <>
        {plan?.benefits.map((row: any, index: number) => {
          return (
            <div key={index} className={classes.benefitOpened}>
              <DoneRoundedIcon htmlColor="#00A9C2" />
              <p className={classes.benefitText}>{row}</p>
            </div>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    setOpenPlanCard(isOpen);
  }, [isOpen]);

  return openPlanCard /*&& isOpen */ ? (
    <>
      {!canceledCard && (
        <>
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            className={classes.dialog}
          >
            <div className={classes.contactDialog}>
              <p className={classes.cancetText}>
                ¿Estás seguro que quieres cancelar {plan.name}?
              </p>
              <ButtonPrimary
                noDegraded
                onClick={() => {
                  setOpenDialog(false);
                  updateUserSubscription(client, { id: data.id });
                  RefreshItems();
                  setCanceledCard(true);
                }}
                text="Cancelar Plan"
              />
              <span
                onClick={() => setOpenDialog(false)}
                className={classes.backDialog}
              >
                Volver
              </span>
            </div>
          </Dialog>
          <div className={classes.planContainerOpened}>
            <div
              className={classes.imageContainer}
              onClick={() => {
                setOpenPlanCard(!openPlanCard);
              }}
            >
              <img src={plan?.image} alt="" />
            </div>
            <div className={classes.textContainerOpened}>
              <div
                className={classes.infoContainerOpened}
                onClick={() => {
                  setOpenPlanCard(!openPlanCard);
                }}
              >
                <div className={classes.InfoLeft}>
                  <p className={classes.descriptionOpened}>
                    {plan?.subtitle?.toUpperCase()}
                  </p>
                  <p className={classes.titleOpened}>{plan?.name}</p>
                </div>
                <div className={classes.InfoRight}>
                  <div className={classes.costInfo}>
                    <p className={classes.dollarSignOpened}>$ </p>
                    <p className={classes.costOpened}>
                      {isDiscount
                        ? parseNumber(plan?.priceDiscount)
                        : parseNumber(plan?.price)}
                    </p>
                  </div>

                  <p className={classes.timesOpened}>/ {plan?.periodicity}</p>
                </div>
              </div>
              <div className={classes.bottom}>
                <div
                  className={classes.serviceStatusContainer}
                  onClick={() => {
                    setOpenPlanCard(!openPlanCard);
                  }}
                >
                  <div className={classes.serviceStatusLeft}>
                    <p>
                      Servicio{' '}
                      {data?.paid ? (
                        <span>Activo</span>
                      ) : (
                        <span className={classes.redText}>Inactivo</span>
                      )}
                    </p>
                    {/*<p>Activado el FECHA</p>*/}
                  </div>
                  <div className={classes.serviceStatusRight}>
                    <span>Estado</span>
                    <p>{data?.paid ? 'pagado' : 'pago pendiente'}</p>
                  </div>
                </div>

                <div
                  className={openSeeMore ? classes.notHidden : classes.hidden}
                  onClick={() => {
                    setOpenPlanCard(!openPlanCard);
                  }}
                >
                  <p className={classes.IncludesText}>Incluye: </p>
                  {GetBenefits()}
                </div>
                <div
                  className={classes.seeMoreButton}
                  onClick={() => {
                    setOpenSeeMore(!openSeeMore);
                  }}
                >
                  {openSeeMore ? <p>Ocultar detalle</p> : <p>Ver detalle</p>}
                </div>
                {/* <div className={classes.selectedContainer}>
              <DoneRoundedIcon htmlColor="#00A9C2" fontSize="large" />
              <p className={classes.selectedText}>Seleccionado</p>
            </div> */}
              </div>
            </div>
            <div
              className={classes.cancelPlanButton}
              onClick={() => setOpenDialog(true)}
            >
              <img src={DELETE_ICON} alt="" className={classes.deleteIcon} />
              <p>Cancelar plan</p>
            </div>
          </div>
        </>
      )}
    </>
  ) : (
    <>
      {!canceledCard && (
        <>
          <div
            className={classes.planContainerOpened}
            onClick={() => {
              setOpenPlanCard(!openPlanCard);
            }}
          >
            <div>
              <div className={classes.planDescription}>
                <p>{plan?.subtitle?.toUpperCase()}</p>
              </div>
              <div className={classes.planTitleClosed}>
                <p>{plan?.name}</p>
              </div>
            </div>
            <div className={classes.rightClosed}>
              <div className={classes.costClosed}>
                <p className={classes.dollarSignClosed}>$ </p>
                <p className={classes.costTextClosed}>
                  {isDiscount
                    ? parseNumber(plan?.priceDiscount)
                    : parseNumber(plan?.price)}
                </p>
              </div>

              <div className={classes.timesClosed}>
                <p>/ {plan?.periodicity}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
