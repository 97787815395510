import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PageProps, navigate } from 'gatsby';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { LayoutOnBoarding, SEO } from '@components';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { useAuth } from '@hooks';
import { HiredPlanCard } from '@components/Services/HiredPlans/HiredPlanCard';
import { getSubscriptionPlans, getUserSubscriptions } from '@apollo';
import _ from 'lodash';
import { Route } from 'interfaces/routes';
import { LayoutMobileInvestments } from '@components/Investments/Layout';

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1270px)');
  const { userSubscriptions } = getUserSubscriptions();
  let data = userSubscriptions;

  useEffect(() => {
    data = userSubscriptions;
  }, [userSubscriptions]);

  const RefreshItems = useCallback(() => {
    data = userSubscriptions;
  }, [data, userSubscriptions]);
  return (
    <>
      <LayoutOnBoarding
        className={classes.layoutOnBoarding}
        hiddenHeader={isMobile}
        activeServices
      >
        {isMobile && (
          <LayoutMobileInvestments activeServices>
            <></>
          </LayoutMobileInvestments>
        )}
        <div className={classes.container}>
          <div className={classes.titleAndButton}>
            <div
              className={classes.backButton}
              onClick={() => {
                navigate(Route.services);
              }}
            >
              <KeyboardArrowLeftOutlinedIcon
                htmlColor="#FFFFFF"
                fontSize={isMobile ? 'small' : 'large'}
              />
            </div>
            <p className={classes.title}>MIS SERVICIOS CONTRATADOS</p>
          </div>
          <p className={classes.subtitle}>Servicios en curso</p>
          <div className={classes.planlistContainer}>
            {data.map((item, index) => {
              return (
                <HiredPlanCard
                  data={item}
                  isOpen={false}
                  RefreshItems={RefreshItems}
                />
              );
            })}
          </div>
        </div>
      </LayoutOnBoarding>
    </>
  );
};

export default SaveByRokinPage;

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    minHeight: '110vh',
    backgroundImage:
      'linear-gradient(155deg, #0a5669 24%, #067b91 66%, #00a9c2 136%)',
    padding: '7.75rem 12.5rem 0 12.5rem',
    marginTop: '-3rem',
    [theme.breakpoints.down(1270)]: {
      padding: '3.75rem 1rem 0 1rem',
    },
  },
  titleAndButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1.3rem',
  },
  backButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '8.6px',
    background: 'rgba(0,0,0,0.6)',
    [theme.breakpoints.down(1270)]: {
      width: '1.25rem',
      height: '1.25rem',
      borderRadius: '5.6px',
    },
  },
  title: {
    fontSize: '1.5rem',
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    letterSpacing: '1.2px',
    [theme.breakpoints.down(1270)]: {
      fontSize: '0.875rem',
      letterSpacing: '0.7px',
    },
  },
  subtitle: {
    fontSize: '1rem',
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    marginTop: '3.75rem',
    marginBottom: '3.25rem',
    [theme.breakpoints.down(1270)]: {
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
  },
  planlistContainer: {
    display: 'grid',
    gap: '2.3rem',
    gridTemplateColumns: '33% 33% 33%',
    paddingBottom: '5rem',
    [theme.breakpoints.down(1270)]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));
