import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { open: boolean }>((theme) => ({
  container: {
    width: '42.5rem',
    minHeight: '60rem',
    display: 'flex',
    flexDirection: 'column',
    background: '#084554',
    padding: '2.5rem 2rem 2.5rem 2rem',
    color: '#FFFFFF',
    gap: '1rem',
    '& p': {},
  },
  planContainerOpened: {
    width: '21.5rem',
    maxHeight: ({ open }) => (open ? '40rem' : '10rem'),
    height: 'fit-content',
    borderRadius: '16px',
    border: ({ open }) => (open ? '' : '1.3px solid #FFFFFF'),
    display: ({ open }) => (open ? 'block' : 'flex'),
    justifyContent: ({ open }) => (open ? 'initial' : 'space-between'),
    transition: 'max-height .7s',
    background: '#FFFFFF',
    boxShadow: '5px 5px 10px 3px rgba(0, 0, 0, 0.2)',
    padding: ({ open }) => (open ? '1rem' : '1.2rem'),
    cursor: 'pointer',
    overflow: 'hidden',
  },
  planDescription: {
    '& p': {
      fontSize: '0.625rem',
      fontWeight: '500',
      fontFamily: 'Montserrat',
      textAlign: 'left',
    },
  },
  planTitleClosed: {
    '& p': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
      textAlign: 'left',
      color: '#0A5A64',
    },
  },
  rightClosed: {
    marginTop: '-0.4rem',
  },
  costClosed: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'right',
  },
  dollarSignClosed: {
    fontSize: '0.5rem',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    textAlign: 'right',
  },
  costTextClosed: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    textAlign: 'right',
    color: '#282828',
  },
  timesClosed: {
    '& p': {
      fontSize: '0.625rem',
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
      textAlign: 'right',
      color: '#959595',
    },
  },
  imageContainer: {
    width: '100%',
    height: '6.375rem',
    borderRadius: '8px',
    background: '#00a9c2',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  infoContainerOpened: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  InfoLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  InfoRight: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.5rem',
  },
  costInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  descriptionOpened: {
    fontSize: '0.625rem',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    color: '#646464',
  },
  titleOpened: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#0A5A64',
  },
  dollarSignOpened: {
    fontSize: '0.5rem',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#393E44',
  },
  costOpened: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#393E44',
  },
  timesOpened: {
    fontSize: '0.625rem',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#9D9D9D',
  },
  benefitOpened: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
    justifyContent: 'left',
    alignItems: 'center',
  },
  IncludesText: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    color: '#646464',
  },
  benefitText: {
    fontSize: '0.75rem',
    fontFamily: 'Montserrat',
    color: '#646464',
  },
  selectedContainer: {
    width: '4rem',
    height: '3rem',
    paddingLeft: '0.8rem',
    display: 'flex',
    flexDirection: 'column',
    //position: "relative",
    border: '1px solid #084554',
    borderRadius: '8px',
    justifyContent: 'center',
  },
  selectedText: {
    fontSize: '0.5rem',
    fontFamily: 'Nunito',
    color: '#6B6B6B',
    width: 'fit-content',
    marginLeft: '-0.35rem',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  choosePlanButton: {
    width: '100%',
  },
  notHidden: {},
  hidden: {
    display: 'none',
  },
  serviceStatusContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3.5rem',
    marginBottom: '1.5rem',
  },
  seeMoreButton: {
    '& p': {
      marginTop: '0.5rem',
      textAlign: 'left',
      width: 'fit-content',
      fontSize: '0.75rem',
      fontFamily: 'Nunito',
      fontWeight: 'normal',
      color: '#7D7C7C',
      textDecoration: 'underline',
      textDecorationColor: '#7D7C7C',
    },
  },
  serviceStatusLeft: {
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    fontWeight: 'normal',
    color: '#646464',
    '& span': {
      color: '#008195',
    },
  },
  serviceStatusRight: {
    fontSize: '0.625rem',
    fontFamily: 'Nunito',
    fontWeight: 'normal',
    color: '#959595',
    '& p': {
      color: '#646464',
      fontSize: '0.75rem',
    },
  },
  cancelPlanButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right',
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    fontWeight: 'normal',
    color: '#282828',
  },
  deleteIcon: {
    filter: '#282828',
  },
  redText: {
    color: 'red!important',
  },
  dialog: {
    '& div': {
      borderRadius: '15px',
    },
  },
  contactDialog: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
  },
  backDialog: {
    textAlign: 'center',
  },
  cancetText: {
    color: '#282828',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}));
export default useStyles;
